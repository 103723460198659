import { Environment } from "@juhuu/sdk-ts";
import React, { useContext, createContext, useMemo } from "react";

export interface EnvironmentContextType {
  environment: Environment;
}

export const EnvironmentContext = createContext<EnvironmentContextType>(
  {} as EnvironmentContextType
);

export function useEnvironment() {
  return useContext(EnvironmentContext);
}

interface EnvironmentProviderProps {
  children?: React.ReactNode;
}

const EnvironmentProvider: React.FC<EnvironmentProviderProps> = ({
  children = null,
}) => {
  const environment = useMemo((): Environment => {
    // extract domain ending .app or .dev
    const domain = window.location.hostname.split(".").slice(-2).join(".");

    console.log("Domain: ", domain);
    if (domain === "juhuu.dev") {
      return "development";
    }

    if (domain === "localhost") {
      return "development";
    }

    return "production";
  }, []);

  const value: EnvironmentContextType = {
    environment: environment,
  };

  return (
    <EnvironmentContext.Provider value={value}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
